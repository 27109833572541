import React from 'react'
import { Link } from 'gatsby'
import styles from './Home.module.scss'

export default () => (
    <section className={`${styles.home} l-wrapper`}>
        <div className={`${styles.logo} animation-logo`}>
            <span>G</span>
            <span>r</span>
            <span>i</span>
            <span>d</span>
            <span>.</span> 
            <span>i</span>
            <span>o</span>
        </div>
        <div className={styles.news}>
            <span>2021.08.05</span><span><Link to="/about">About</Link>の情報をを更新しました。</span> <a href="/news" className={styles.button}>Read more</a>
        </div>
    </section>
)